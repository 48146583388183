const prepareWalletsData = (data) => {
    const result = {};
    Object.keys(data).forEach((key) => {
        const minPoint = data[Number(key)][data[Number(key)].length - 1][1];
        result[Number(key)] = data[Number(key)].map((dataElem, index) => {
            var _a;
            return ({
                date: new Date(dataElem[0]),
                value: dataElem[1],
                percent: (dataElem[1] - minPoint) / minPoint,
                walletKey: Number(key),
                isIndexPoint: index === (((_a = data[Number(key)]) === null || _a === void 0 ? void 0 : _a.length) || 1) - 1,
            });
        });
    });
    return result;
};
export default prepareWalletsData;
