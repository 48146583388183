const WALLETS_PROFIT_DATA = {
    0: [
        ['Fri Apr 26 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 210.764],
        ['Thu Apr 25 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 180.764],
        ['Wed Apr 24 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 147.103],
        ['Tue Apr 23 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 153.599],
        ['Mon Apr 22 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 1.26654],
        ['Mon Apr 21 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 67.5262],
        ['Mon Apr 20 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 59.2499],
    ],
    1: [
        ['Fri Apr 26 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 1.62451],
        ['Thu Apr 25 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 12.3107],
        ['Wed Apr 24 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 44.3107],
        ['Tue Apr 23 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 24.5832],
        ['Mon Apr 22 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 63.5324],
    ],
    2: [
        ['Fri Apr 26 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 11.6538],
        ['Thu Apr 25 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 7.75577],
        ['Wed Apr 24 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 1.23499],
        ['Tue Apr 23 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 11.6538],
        ['Mon Apr 22 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 11.6538],
        ['Mon Apr 21 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 68.2112],
        ['Mon Apr 20 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 83.1702],
    ],
    3: [
        ['Fri Apr 26 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 153.666],
        ['Thu Apr 25 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 153.666],
        ['Wed Apr 24 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 11.9365],
        ['Tue Apr 23 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 1.59778],
        ['Mon Apr 22 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 18.1168],
        ['Mon Apr 21 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 107.789],
        ['Mon Apr 20 2024 00:00:00 GMT+0300 (Москва, стандартное время)', 131.506],
    ],
};
export default WALLETS_PROFIT_DATA;
