import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { ExchangeWallet } from '@features/wallet/exchange-wallet';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { PlusIcon } from '@shared/ui/misc/PaymentSelect/icons';
import { checkIfWalletSupported } from '@entities/wallet';
import { ConnectExchangeButton } from '@features/exchange/exchange-connect';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import SkeletonGroup from '@shared/ui/progress-loaders/SkeletonGroup';
import UnsupportedWalletsList from '../UnsupportedWalletsList';
import { ListTitle } from '../shared';
import SupportedWalletsList from '../components/SupportedWalletsList';
const ExchangeWalletsList = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.l,
    flexWrap: 'wrap',
}));
const ExchangesListHeader = styled(ContainerRow)(props => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: props.theme.spacing_sizes.l,
}));
const ClientOverview = ({ exchanges = [], wallets = [], portfolios = [], supportedWalletsDict, isLoading = false, }) => {
    const { t } = useTranslation();
    const { supportedWallets, unsupportedWallets, } = useMemo(() => wallets
        .reduce((acc, wallet) => {
        const isSupported = checkIfWalletSupported(wallet, supportedWalletsDict);
        if (isSupported) {
            acc.supportedWallets.push(wallet);
        }
        else {
            acc.unsupportedWallets.push(wallet);
        }
        return acc;
    }, {
        supportedWallets: [],
        unsupportedWallets: [],
    }), [wallets, supportedWalletsDict]);
    return (_jsx(SkeletonGroup, { isLoading: isLoading, children: _jsxs(ContainerColumn, { children: [_jsxs(ExchangesListHeader, { children: [_jsxs(ListTitle, { children: [t('overview.exchange_wallets.title'), (wallets === null || wallets === void 0 ? void 0 : wallets.length) && !isLoading ? ` (${wallets.length})` : ''] }), _jsx(WithSkeleton, { isLoading: isLoading, width: 210, height: 40, children: _jsx(ConnectExchangeButton, { variant: 'tinted', startIcon: PlusIcon, children: t('overview.exchange_wallets.connect_exchange') }) })] }), _jsx(ExchangeWalletsList, { children: _jsx(SupportedWalletsList, { wallets: wallets, portfolios: portfolios, isLoading: isLoading }) }), (unsupportedWallets === null || unsupportedWallets === void 0 ? void 0 : unsupportedWallets.length) > 0 && (_jsx(UnsupportedWalletsList, { count: unsupportedWallets === null || unsupportedWallets === void 0 ? void 0 : unsupportedWallets.length, children: unsupportedWallets.map((wallet) => (_jsx(ExchangeWallet, { wallet: wallet, portfolios: portfolios, isSupported: false }, wallet.id))) }))] }) }));
};
export default ClientOverview;
